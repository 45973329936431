<template>
    <div>
    <b-alert
        v-model="updAlert"
        class="position-fixed fixed-top m-0 rounded-0"
        style="z-index: 3000 !important;"
        variant="danger"
        dismissible
    >
    Внимание: Данные по нормативам распределения изменились, обновите данные.
    </b-alert>
        <div class="filter-container">
            <b-dropdown class="filter-dropdown" variant="default" ref="drop">
                <template #button-content>
                    <span class="lc"><i class="icon icon-filter"></i> Фильтр</span><span class="rc"><i class="icon icon-keyboard"></i></span>
                </template>
                <div>
                    <div class="top-content">
                        <span>Параметры фильтра</span>
                        <i class="icon icon-close"></i>
                    </div>
                    <div class="filter-block">
                        <b-form-group label="Плановый период">
                            <multiselect ref="lfPlanPeriod"
                                v-model="curPeriod"
                                track-by="name"
                                label="name"
                                @input="filterVariants(false)"
                                placeholder="Выбрать период"
                                :options="periodLst"
                                :searchable="true"
                                :allow-empty="false"
                                :show-labels="false"
                            >
                            </multiselect>
                        </b-form-group>
                    </div>
                    <div class="filter-block">
                        <b-form-group label="Область/район">
                             <multiselect ref="lfRegionBudget"
                                v-model="regObj"
                                track-by="text"
                                label="text"
                                :options="listReg"
                                :searchable="true"
                                :allow-empty="false"
                                @input="filterVariants(false)"
                                :show-labels="false">
                            </multiselect>
                        </b-form-group>
                    </div>
                </div>
                <!----------------------------Вид данных---------------------->
                <div class="filter-block">
                    <b-form-group label="Вид данных">
                        <multiselect
                            v-model="curDataType"
                            track-by="text"
                            label="text"
                            placeholder="Вид данных"
                            :options="filteredDataTypeDict"
                            @input="filterVariants(false, true)"
                            :searchable="false"
                            :allow-empty="false"
                            :show-labels="false"
                            ref="dataTypeRef"
                        >
                        </multiselect>
                        
                    </b-form-group>
                </div>

                <div class="filter-block">
                    <b-form-group label="Версия бюджета">
                        <multiselect ref="lfVariant"
                            v-model="variantObj"
                            track-by="text"
                            label="text"
                            :options="listOfVariants"
                            :searchable="true"
                            :allow-empty="false"
                            @input="updateDatas"
                            :show-labels="false">
                        </multiselect>
                    </b-form-group>
                </div>
            </b-dropdown>
            <div class="filter-actions">
                <b-button variant="secondary" :disabled="dsblUpdBtn" @click="updateData">Обновить данные</b-button>
                <b-button variant="secondary" :disabled="dsblSendBtn" @click="sendDatasToBugetForm">Отправить свод</b-button>
                <b-button :disabled="dsblSaveBtn" variant="success" @click="saveDatas">Сохранить</b-button>
            </div>
        </div>

        <c-breadcrumbs
            :bCrumbs="[
                {obj: curPeriod, field: 'name', bcRef: 'lfPlanPeriod'},
                {obj: regObj, field: 'text', bcRef: 'lfRegionBudget'},
                {obj: variantObj, field: 'text', bcRef: 'lfVariant'},
            ]"
            @openFilterByRef="openFilterByRef">
        </c-breadcrumbs>

        <b-progress variant="primary" v-show="bar<100" height="3px" :value="bar" striped animated></b-progress>
        <div class="table-container">
            <b-table
                :fields="tableFields"
                :items="budgetForm"
                :tbody-tr-class="rowClass"
                :filter="filter"
                :filter-function="filterFunction"
                responsive="true"
                bordered
                head-variant="light"
                no-border-collapse
                sticky-header="true"
            >
                <template #thead-top="data">
                    <b-tr>
                        <b-th colspan="10"></b-th>
                        <b-th colspan="3">{{ 'Прогноз на ' + (parseInt(repYear) + 1) + ' год, тыс.тенге' }}</b-th>
                        <b-th></b-th>
                        <b-th colspan="3">{{ 'Прогноз на ' + (parseInt(repYear) + 2) + ' год, тыс.тенге' }}</b-th>
                        <b-th></b-th>
                        <b-th colspan="3">{{ 'Прогноз на ' + (parseInt(repYear) + 3) + ' год, тыс.тенге' }}</b-th>
                        <b-th colspan="2"></b-th>
                    </b-tr>
                </template>

                <template #head(kat)="scope">
                    <div>{{ tableFields[1].label }}</div>
                    <b-form-input
                        id="filter-input"
                        v-model="filter.kat"
                        type="search"
                        class="mini"
                    ></b-form-input>
                </template>
                <template #head(cls)="scope">
                    <div>{{ tableFields[2].label }}</div>
                    <b-form-input
                        id="filter-input"
                        v-model="filter.cls"
                        type="search"
                        class="mini"
                    ></b-form-input>
                </template>
                <template #head(pcl)="scope">
                    <div>{{ tableFields[3].label }}</div>
                    <b-form-input
                        id="filter-input"
                        v-model="filter.pcl"
                        type="search"
                        class="mini"
                    ></b-form-input>
                </template>
                <template #head(spf)="scope">
                    <div>{{ tableFields[4].label }}</div>
                    <b-form-input
                        id="filter-input"
                        v-model="filter.spf"
                        type="search"
                        class="mini"
                    ></b-form-input>
                </template>
                <template #head(name_ru)="scope">
                    <div>{{ tableFields[5].label + decDigitsAmnt}}</div>
                    <b-form-input
                        id="filter-input"
                        v-model="filter.name_ru"
                        type="search"
                    ></b-form-input>
                </template>
                <template #head(receipt_prev)>
                    <div>{{ 'Поступления за ' + (parseInt(repYear) - 1) + 'г.(факт), тыс.тенге' }}</div>
                </template>
                <template #head(receipt_curr)>
                    <div>{{ 'Поступления за ' + (repYear) + 'г.(факт) на '+ (lastMonth) + ' мес., тыс.тенге' }}</div>
                </template>
                <template #head(revised_plan)>
                    <div>{{ 'Уточненный план ' + (repYear) + ' года' + (revisedPlanMaxDat ? ' на ' + revisedPlanMaxDat + 'г.': '')}}</div>
                </template>
                <template #head(receipt_mark)>
                    <div>{{ 'Поступления за ' + (repYear) + 'г.(оценка), тыс.тенге' }}</div>
                </template>
                <template #head(action)="scope">
                    <b-button @click="openAll()">
                        <i class="icon icon-chevron-circle icon-rotate-180" v-if="open"></i>
                        <i class="icon icon-chevron-circle" v-if="!open"></i>
                    </b-button>
                </template>

                <template #cell(action)="data">
                    <b-button v-if="data.item.type === 1" @click="openChilds(data.item)">
                        <i class="icon icon-chevron-circle icon-rotate-180" v-if="data.item.open"></i>
                        <i class="icon icon-chevron-circle" v-if="!data.item.open"></i>
                    </b-button>
                </template>
                <template #cell(kat)="data">
                    <div v-if="data.item.type === 1 || filtered === true" v-bind:class="{filtered : filtered === true && data.item.clss !== null}">{{ data.value }}</div>
                    <b-button v-if="data.item.type === 2" @click="openChilds(data.item)">
                        <i class="icon icon-chevron-circle icon-rotate-180" v-if="data.item.open"></i>
                        <i class="icon icon-chevron-circle" v-if="!data.item.open"></i>
                    </b-button>
                </template>
                <template #cell(cls)="data">
                    <div v-if="data.item.type === 2 || (filtered === true && data.item.clss !== null)" v-bind:class="{filtered : filtered === true && data.item.pcl !== null}">{{ data.value }}</div>
                    <b-button v-if="data.item.type === 3" @click="openChilds(data.item)">
                        <i class="icon icon-chevron-circle icon-rotate-180" v-if="data.item.open"></i>
                        <i class="icon icon-chevron-circle" v-if="!data.item.open"></i>
                    </b-button>
                </template>
                <template #cell(pcl)="data">
                    <div v-if="data.item.type === 3 || filtered === true" v-bind:class="{filtered : filtered === true && data.item.pcl !== null}">{{ data.value }}</div>
                </template>
                <template #cell(spf)="data">
                    <div v-if="data.item.type === 5">{{ data.value }}</div>
                </template>
                <template #cell(name_ru)="data">
                    <div>{{ data.value }}</div>
                </template>

                <template #cell()="data">
                    <div class="text-right">{{ data.value ? $n(toNum(data.value)) : 0 }}</div>
                </template>
                <template #cell(receipt_mark)="data">
                    <template v-if="data.item.type === 5">
                        <div :class="{ 'error': toNum(data.item.receipt_mark) < toNum(data.item.receipt_prev) }">
                            <b-form-input class="text-right"
                                          :disabled = "isItemDisabled"
                                          :value="data.item.receipt_mark"
                                          @change="v => data.item.receipt_mark = v"
                                          @keyup.enter.exact="keyup13"
                                          @keypress="keyPress($event, getRegExpr)"
                                          @blur="inputFixed(data.item, 'receipt_mark', data.item.receipt_mark, decDigitsAmnt)">
                            </b-form-input>
                            <template v-if="toNum(data.item.receipt_mark) < toNum(data.item.receipt_prev)">
                                <i class="icon icon-danger"></i>
                                <div class="pop-up">
                                    <p class="red-text">Внимание! Данные не соответствуют контролю: </p>
                                    <p>"Поступления за {{ repYear }}г.(оценка)" ≥ "Поступления за {{ parseInt(repYear) - 1 }}г.(факт)"</p>
                                </div>
                            </template>
                        </div>
                    </template>
                    <template v-else>
                        <div class="text-right">{{ $n(toNum(data.value, decDigitsAmnt)) }}</div>
                    </template>
                </template>
                <template #cell(contingent1)="data">
                    <template v-if="data.item.type === 5">
                        <template>
                            <template v-if="region.endsWith('0101') && data.item.standard.regional1">
                                <div v-if="data.item.summ1.length > 0" class="more-info text-right text-only more-info-right">
                                    <span>
                                        <i v-if="data.item.inaccurate1" class="icon icon-danger"></i>
                                        {{ $n(toNum(data.item.contingent1, decDigitsAmnt)) }} 
                                    </span>
                                    <br>
                                        <div class="pop-up">
                                            <p>Поступление по городам/районам:</p>
                                            <table>
                                                <template v-for="(row, i) in data.item.summ1">
                                                <tr v-if="row.__data__.regional > 0" :key="i">
                                                    <td>{{ row.__data__['name_'+$i18n.locale] }}</td>
                                                    <td>
                                                        <span>
                                                            {{ $n(toNum(row.__data__.regional, decDigitsAmnt)) }}
                                                        </span>
                                                    </td>
                                                </tr>
                                                </template>
                                            </table>
                                            <span v-if="data.item.inaccurate1">Внимание: данные не соответствуют нормативам</span>
                                        </div>
                                    </div>
                                <div v-else>0</div>
                            </template>
                            <template v-else>
                                <b-form-input class="text-right"
                                              :value="data.item.contingent1"
                                              @change="v => data.item.contingent1 = v"
                                              @keyup.enter.exact="keyup13"
                                              @keypress="keyPress($event, getRegExpr)"
                                              @blur="inputFixed(data.item, 'contingent1', data.item.contingent1, decDigitsAmnt)"
                                              v-if="!isItemDisabled"
                                >
                                </b-form-input>
                                <span class="global-input-style disabled" v-else>{{data.item.contingent1}}</span>
                            </template>
                        </template>
                    </template>
                    <template v-else>
                        <div class="text-right">{{ $n(toNum(data.value, decDigitsAmnt)) }}</div>
                    </template>
                </template>
                <template #cell(regional1)="data">
                    <template v-if="data.item.type === 5">
                        <template v-if="data.item.crossfields.includes('regional1')">
                            <div class="text-center">X</div>
                        </template>
                        <template v-else>
                            <div :class="{'error': data.item.reg1}" class="more-info text-right more-info-right">
                                <span class="warning-content">
                                    <i v-if="data.item.reg1" class="icon icon-danger"></i>
                                    {{ $n(toNum(data.value, decDigitsAmnt)) }}
                                    <div class="pop-up">
                                        <p>Внимание: данные не соответствуют нормативам</p>
                                        <p class="red-text">Сохраненные: {{ $n(toNum(data.value, decDigitsAmnt)) }}</p>
                                        <p>Фактические: {{ $n(toNum(data.item.cregional1, decDigitsAmnt)) }}</p>
                                    </div>
                                </span>
                            </div>
                        </template>
                    </template>
                    <template v-else>
                        <div class="text-right">{{ $n(toNum(data.value, decDigitsAmnt)) }}</div>
                    </template>
                </template>
                <template #cell(district1)="data">
                    <template v-if="data.item.type === 5">
                        <template v-if="data.item.crossfields.includes('district1')">
                            <div class="text-center">X</div>
                        </template>
                        <template v-else>
                            <div :class="{'error': data.item.dstr1}" class="more-info text-right more-info-right" >
                                <span class="warning-content">
                                    <i v-if="data.item.dstr1" class="icon icon-danger"></i>
                                    {{ $n(toNum(data.value, decDigitsAmnt)) }}
                                    <div class="pop-up">
                                        <p>Внимание: данные не соответствуют нормативам</p>
                                    </div>
                                </span>
                                <br>
                            </div>
                        </template>
                    </template>
                    <template v-else>
                        <div class="text-right">{{ $n(toNum(data.value, decDigitsAmnt)) }}</div>
                    </template>
                </template>
                <template #cell(contingent2)="data">
                    <template v-if="data.item.type === 5">
                        <template v-if="region.endsWith('0101') && data.item.standard.regional2">
                            <div v-if="data.item.summ2.length > 0" class="more-info text-right text-only more-info-right">
                                <span>
                                    <i v-if="data.item.inaccurate2" class="icon icon-danger"></i>
                                    {{ $n(toNum(data.item.contingent2, decDigitsAmnt)) }} 
                                </span>
                                <br>
                                <div class="pop-up">
                                    <p>Поступление по городам/районам:</p>
                                    <table>
                                        <template v-for="(row, i) in data.item.summ2">
                                            <tr v-if="row.__data__.regional > 0" :key="i">
                                                <td>{{ row.__data__['name_'+$i18n.locale] }}</td>
                                                <td>
                                                    <span>
                                                        {{ $n(toNum(row.__data__.regional, decDigitsAmnt)) }}
                                                    </span>
                                                </td>
                                            </tr>
                                        </template>
                                    </table>
                                    <span v-if="data.item.inaccurate2">Внимание: данные не соответствуют нормативам</span>
                                </div>
                            </div>
                            <div v-else>0</div>
                        </template>
                        <template v-else>
                            <b-form-input class="text-right"
                                          :value="data.item.contingent2"
                                          @change="v => data.item.contingent2 = v"
                                          @keyup.enter.exact="keyup13"
                                          @keypress="keyPress($event, getRegExpr)"
                                          @blur="inputFixed(data.item, 'contingent2', data.item.contingent2, decDigitsAmnt)"
                                          v-if="!isItemDisabled"
                            >
                            </b-form-input>
                            <span class="global-input-style disabled" v-else>{{data.item.contingent2}}</span>
                        </template>
                    </template>
                    <template v-else>
                        <div class="text-right">{{ $n(toNum(data.value, decDigitsAmnt)) }}</div>
                    </template>
                </template>
                <template #cell(regional2)="data">
                    <template v-if="data.item.type === 5">
                        <template v-if="data.item.crossfields.includes('regional2')">
                            <div class="text-center">X</div>
                        </template>
                        <template v-else>
                            <div :class="{'error': data.item.reg2}" class="more-info text-right more-info-right">
                                <span class="warning-content">
                                    <i v-if="data.item.reg2" class="icon icon-danger"></i>
                                    {{ $n(toNum(data.value, decDigitsAmnt)) }}
                                    <div class="pop-up">
                                        <p>Внимание: данные не соответствуют нормативам</p>
                                        <p class="red-text">Сохраненные: {{ $n(toNum(data.value, decDigitsAmnt)) }}</p>
                                        <p>Фактические: {{ $n(toNum(data.item.cregional2, decDigitsAmnt)) }}</p>
                                    </div>
                                </span>
                            </div>
                        </template>
                    </template>
                    <template v-else>
                        <div class="text-right">{{ $n(toNum(data.value, decDigitsAmnt)) }}</div>
                    </template>
                </template>
                <template #cell(district2)="data">
                    <template v-if="data.item.type === 5">
                        <template v-if="data.item.crossfields.includes('district2')">
                            <div class="text-center">X</div>
                        </template>
                        <template v-else>
                            <div :class="{'error': data.item.dstr2}" class="more-info text-right more-info-right" >
                                <span class="warning-content">
                                    <i v-if="data.item.dstr2" class="icon icon-danger"></i>
                                    {{ $n(toNum(data.value, decDigitsAmnt)) }}
                                    <div class="pop-up">
                                        <p>Внимание: данные не соответствуют нормативам</p>
                                    </div>
                                </span>
                                <br>
                            </div>
                        </template>
                    </template>
                    <template v-else>
                        <div class="text-right">{{ $n(toNum(data.value, decDigitsAmnt)) }}</div>
                    </template>
                </template>
                <template #cell(contingent3)="data">
                    <template v-if="data.item.type === 5">
                        <template v-if="region.endsWith('0101') && data.item.standard.regional3">
                            <div v-if="data.item.summ3.length > 0" class="more-info text-right text-only more-info-right">
                                <span>
                                    <i v-if="data.item.inaccurate3" class="icon icon-danger"></i>
                                    {{ $n(toNum(data.item.contingent3, decDigitsAmnt)) }} 
                                </span>
                                <br>
                                <div class="pop-up">
                                    <p>Поступление по городам/районам:</p>
                                    <table>
                                        <template v-for="(row, i) in data.item.summ3">
                                            <tr v-if="row.__data__.regional > 0" :key="i">
                                                <td>{{ row.__data__['name_'+$i18n.locale] }}</td>
                                                <td>
                                                    <span>
                                                        {{ $n(toNum(row.__data__.regional, decDigitsAmnt)) }}
                                                    </span>
                                                </td>
                                            </tr>
                                        </template>
                                    </table>
                                    <span v-if="data.item.inaccurate3">Внимание: данные не соответствуют нормативам</span>
                                </div>
                            </div>
                            <div v-else>0</div>
                        </template>
                        <template v-else>
                            <b-form-input class="text-right"
                                          :disabled = "isItemDisabled"
                                          :value="data.item.contingent3"
                                          @change="v => data.item.contingent3 = v"
                                          @keyup.enter.exact="keyup13"
                                          @keypress="keyPress($event, getRegExpr)"
                                          @blur="inputFixed(data.item, 'contingent3', data.item.contingent3, decDigitsAmnt)"
                                          v-if="!isItemDisabled"
                            >
                            </b-form-input>
                            <span class="global-input-style disabled" v-else>{{data.item.contingent3}}</span>
                        </template>
                    </template>
                    <template v-else>
                        <div class="text-right">{{ $n(toNum(data.value, decDigitsAmnt)) }}</div>
                    </template>
                </template>
                <template #cell(regional3)="data">
                    <template v-if="data.item.type === 5">
                        <template v-if="data.item.crossfields.includes('regional3')">
                            <div class="text-center">X</div>
                        </template>
                        <template v-else>
                            <div :class="{'error': data.item.reg3}" class="more-info text-right more-info-right">
                                <span class="warning-content">
                                    <i v-if="data.item.reg3" class="icon icon-danger"></i>
                                    {{ $n(toNum(data.value, decDigitsAmnt)) }}
                                    <div class="pop-up">
                                        <p>Внимание: данные не соответствуют нормативам</p>
                                        <p class="red-text">Сохраненные: {{ $n(toNum(data.value, decDigitsAmnt)) }}</p>
                                        <p>Фактические: {{ $n(toNum(data.item.cregional3, decDigitsAmnt)) }}</p>
                                    </div>
                                </span>
                            </div>
                        </template>
                    </template>
                    <template v-else>
                        <div class="text-right">{{ $n(toNum(data.value, decDigitsAmnt)) }}</div>
                    </template>
                </template>
                <template #cell(district3)="data">
                    <template v-if="data.item.type === 5">
                        <template v-if="data.item.crossfields.includes('district3')">
                            <div class="text-center">X</div>
                        </template>
                        <template v-else>
                            <div :class="{'error': data.item.dstr3}" class="more-info text-right more-info-right" >
                                <span class="warning-content">
                                    <i v-if="data.item.dstr3" class="icon icon-danger"></i>
                                    {{ $n(toNum(data.value, decDigitsAmnt)) }}
                                    <div class="pop-up">
                                        <p>Внимание: данные не соответствуют нормативам</p>
                                    </div>
                                </span>
                                <br>
                            </div>
                        </template>
                    </template>
                    <template v-else>
                        <div class="text-right">{{ $n(toNum(data.value, decDigitsAmnt)) }}</div>
                    </template>
                </template>
                <template #cell(note)="data">
                    <textarea v-if="(data.item.type === 5)"
                              :disabled = "isItemDisabled"
                              style="height: 100%"
                              cols="50"
                              v-model="data.item.note"
                              @focusout="editing = true">
                    </textarea>
                </template>

                <template #bottom-row="data">
                    <td></td>
                    <td class="text-left" colspan="5">ИТОГО</td>
                    <td class="text-right">{{ $n(total('receipt_prev')) }}</td>
                    <td class="text-right">{{ $n(total('receipt_curr')) }}</td>
                    <td class="text-right">{{ $n(total('revised_plan')) }}</td>
                    <td class="text-right">{{ $n(total('receipt_mark', decDigitsAmnt)) }}</td>
                    <td class="text-right">{{ $n(total('contingent1', decDigitsAmnt)) }}</td>
                    <td class="text-right">{{ $n(total('regional1', decDigitsAmnt)) }}</td>
                    <td class="text-right">{{ $n(total('district1', decDigitsAmnt)) }}</td>
                    <td></td>
                    <td class="text-right">{{ $n(total('contingent2', decDigitsAmnt)) }}</td>
                    <td class="text-right">{{ $n(total('regional2', decDigitsAmnt)) }}</td>
                    <td class="text-right">{{ $n(total('district2', decDigitsAmnt)) }}</td>
                    <td></td>
                    <td class="text-right">{{ $n(total('contingent3', decDigitsAmnt)) }}</td>
                    <td class="text-right">{{ $n(total('regional3', decDigitsAmnt)) }}</td>
                    <td class="text-right">{{ $n(total('district3', decDigitsAmnt)) }}</td>
                    <td colspan="2"></td>
                </template>
            </b-table>
        </div>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import store from '@/services/store';
import {Ax} from '@/utils';
import { variantForLimitIncome, variantNameLangSupport } from '@/modules/budget/budgetCorrectHelper';
import CBreadcrumbs from './components/c-breadcrumbs.vue';
import Decimal from 'decimal.js';
import { forEach } from 'lodash';


export default {
    name: 'LimitForm',
    components: {CBreadcrumbs},
    props: {
        year: Number,
        reg: String,
        listReg: Array,
        listVariants: Array,
        dataTypeDict: Array,
        userRegion: String,
        obl: String,
        accessLevel: { type: Number, required: false,  default: 0 }
    },
    data() {
        return {
            tableFields: [
                {
                    key: 'action',
                    label: ' ',
                    class: 'toggle-show'
                },
                {
                    key: 'kat',
                    label: 'КАТ',
                    class: 'toggle-show'
                },
                {
                    key: 'cls',
                    label: 'КЛ',
                    class: 'toggle-show'
                },
                {
                    key: 'pcl',
                    label: 'ПКЛ',
                    class: 'toggle-show'
                },
                {
                    key: 'spf',
                    label: 'СП'
                },
                {
                    key: 'name_ru',
                    label: 'Наименование'
                },
                {
                    key: 'receipt_prev',
                    label: ''
                },
                {
                    key: 'receipt_curr',
                    label: ''
                },
                {
                    key: 'revised_plan',
                    label: ''
                },
                {
                    key: 'receipt_mark',
                    label: ''
                },
                {
                    key: 'contingent1',
                    label: 'контингент'
                },
                {
                    key: 'regional1',
                    label: 'областной'
                },
                {
                    key: 'district1',
                    label: 'город/район/МСУ'
                },
                {
                    key: 'rate1',
                    label: 'Темп роста, %'
                },
                {
                    key: 'contingent2',
                    label: 'контингент'
                },
                {
                    key: 'regional2',
                    label: 'областной'
                },
                {
                    key: 'district2',
                    label: 'город/район/МСУ'
                },
                {
                    key: 'rate2',
                    label: 'Темп роста, %'
                },
                {
                    key: 'contingent3',
                    label: 'контингент'
                },
                {
                    key: 'regional3',
                    label: 'областной'
                },
                {
                    key: 'district3',
                    label: 'город/район/МСУ'
                },
                {
                    key: 'rate3',
                    label: 'Темп роста, %'
                },
                {
                    key: 'note',
                    label: 'Примечание'
                },
                {
                    key: 'filter',
                    label: 'filter',
                    thClass: 'd-none',
                    tdClass: 'd-none'
                }
            ],
            calcFlds: [
                'receipt_prev',
                'receipt_curr',
                'revised_plan',
                'receipt_mark',
                'contingent1',
                'regional1',
                'district1',
                'contingent2',
                'regional2',
                'district2',
                'contingent3',
                'regional3',
                'district3'
            ],
            filter: {
                kat: null,
                cls: null,
                pcl: null,
                spf: null,
                name_ru: null
            },

            dictTree: null,

            budgetForm: [],
            spfMap: new Map(),
            valMap: new Map(),
            region: null,
            regObj: null,
            open: true,
            editing: false,
            bar: 0,
            repYear: 0,
            curYear: 0,
            strDate: '',
            curPeriod: {},
            periodLst: [],
            filtered: false,
            lastMonth: 0,
            listOfVariants: [],
            variant: '',
            variantObj: null,
            isApproved: true,
            changedRows: [],
            regionVariants: [],
            listRegs: [],
            regVariant: {},
            currObl: null,
            ifDistrStandart: false, //есть ли распределение на область
            standards: [],
            dsblSaveBtn: false,
            dsblUpdBtn: false,
            dsblSendBtn: false,
            updAlert: false,
            revisedPlanMaxDat: null,
            curDataType: null
        };
    },

    created() {
        this.$watch('year', this.repYear = this.year-1);
        this.$watch('reg', this.region = this.reg);
        this.$watch('listReg', () => this.filterVariants(true))
        this.$watch('curPeriod', function () {
            if (this.curPeriod.year) {
                this.repYear = this.curPeriod.year;
                this.$emit('currentYearData', this.repYear);
            }
        });
        this.$watch('listVariants', () => this.filterVariants(true));
    },

    computed: {
        isItemDisabled() {
            const levelAccessToEdit = [2, 3];
            return !this.variantObj.attribute || !levelAccessToEdit.includes(this.accessLevel);
        },
        filteredDataTypeDict() {
            return this.dataTypeDict.filter(item => +item.code !== 5);
        },
        decDigitsAmnt() {
            const isHasDataType = this.variantObj && this.variantObj.hasOwnProperty('data_type');
            const data_types_with_one_decimal_in_numbers = [1, 2, 3, 4];
            if (isHasDataType && data_types_with_one_decimal_in_numbers.includes(this.variantObj.data_type)) {
                return 1;
            }
            return 0;
        }, 

        getRegExpr() {
            if (this.decDigitsAmnt === 1) return '^\\d{0,9}\\.?\\,?\\d{0,9}$';
            return '^\\d{0,9}$';
        }
    },

    async mounted() {
        const date = new Date();
        this.strDate = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear();
        this.curYear = date.getFullYear();
        this.filterVariants(true);
        this.getPeriodLst();
    },

    beforeUpdate() {
        this.budgetForm.forEach(row => {
            if (row.type < 5) {
                row._rowVariant = 'info';
            }
            if (!(row.receipt_mark >= row.receipt_prev)) {
                this.$set(row, '_cellVariants', { receipt_mark: 'warning' });
            }
        });
    },

    methods: {
        async fetchUpdatedData() {
            this.updateDatas();
        },

        async filterVariants(variantsChanged = false, isDataTypeChanged = false) {
            this.revisedPlanMaxDat = null
            if (!this.regObj && this.listReg.length) {
                this.listReg.map(item => {
                    item.text = `${item.code} - ${item.name_ru}`
                    if (this.$i18n.locale === 'kk') item.text = `${item.code} - ${item.name_kk}`
                });
                this.regObj = this.listReg[0];
            }
            if (this.listVariants.length === 0 || !this.regObj) { return; }
            this.region = this.regObj.code;
            const dataTypeCode = isDataTypeChanged && this.curDataType && this.curDataType.code ? +this.curDataType.code : null;
            this.listOfVariants = variantForLimitIncome(this.listVariants, this.curPeriod.year + 1, this.region, dataTypeCode);
            this.listOfVariants.map(item => item.text = this.getVariantName(item))
            this.variantObj = this.listOfVariants.length > 0 ? this.findCurVariant(this.listOfVariants) : null
            if (!isDataTypeChanged) {
                this.setCurDataType(this.variantObj);
                return;
            }
            if (this.listOfVariants.length > 0) {
                this.variant = this.listOfVariants[0].variant_uuid;
                this.isApproved =  this.listOfVariants[0].status;
                if (variantsChanged) {
                    await this.loadDictTree();
                    this.bar = 70;
                    await this.prepareForm();
                    this.rememberDatas();
                    this.bar = 100;
                } else {
                    this.updateDatas();
                }
            } else {
                this.makeToast('warning', 'Предупреждение', 'Версия бюджета не найдена');
                this.budgetForm = []
            }
        },
        
        setCurDataType(curVariant) {
            this.curDataType = this.dataTypeDict.find(item => +item.code === curVariant.data_type);
            this.filterVariants(false, true);
        },

        getVariantName(variant) {
            return variantNameLangSupport(variant, this.$i18n.locale);
        },

        // выбор варианта для отображения по умолчанию
        findCurVariant(bvDictBase) {
            // по умолчанию отображается актуальная версия бюджета
            const actualVariant = bvDictBase.find(itm => {
                const isVariantActive = itm.hasOwnProperty('attribute') && itm.attribute;
                if (isVariantActive) return true;
                return false;
            })
            if (actualVariant) return actualVariant;

            // если нет актуальной, то последняя утвержденная версия
            const approvedVariants = bvDictBase
                                    .filter(itm => {
                                        const isVariantApproved = itm.hasOwnProperty('status') && itm.status;
                                        if (isVariantApproved) return true;
                                        return false;
                                    })
                                    .sort((a, b) => {
                                        if (a.date_ueb && b.date_ueb) {
                                            if (a.date_ueb > b.date_ueb) {
                                                return -1;
                                            }
                                            if (a.date_ueb < b.date_ueb) {
                                                return 1;
                                            }
                                        }
                                        return 0;
                                    })
            if (approvedVariants && approvedVariants.length > 0) return approvedVariants[0];
            
            // в противном случае любая имеющаяся (наибольшая по айди)
            return bvDictBase[0];
        },

        clearDatas() {
            const that = this;

            for (const spf of that.spfMap.values()) {
                that.$set(spf, 'note', '');
                that.$set(spf, 'crossfields', []);
                that.$set(spf, 'standard', {});
                that.calcFlds.forEach((field) => {
                    that.$set(spf, field, 0);
                });
            }
        }, // очищает данные таблицы

        createTable(elem, parent_id) { // создание таблицы на основе дерева
            this.bar += 2;
            const that = this;

            if (elem.last_month && !this.lastMonth) {
                this.lastMonth = elem.last_month;
            }

            const item = Object.assign({}, elem);

            this.$set(item, 'parent_id', parent_id);
            this.$set(item, 'visible', true);

            Object.defineProperty(item, 'parent', {
                get: function () {
                    for (const row of that.budgetForm) {
                        if (item.parent_id === row.id) {
                            return row;
                        }
                    }
                }
            });

            if (item.type === 5) {
                this.spfMap.set(this.getRowKey(item, ['kat', 'cls', 'pcl', 'spf']), item);

            } else {
                this.$set(item, 'open', true);
                this.$set(item, 'hasChild', true);

                this.calcFlds.forEach(field => {
                    Object.defineProperty(item, field, {
                        get: function () {
                            return that.reSum(item, field);
                        }
                    });
                });
            }

            Object.defineProperty(item, 'rate1', {
                get: function () {
                    let rate = 0;
                    if (that.region.endsWith('0101')) {
                       const regionalDec = new Decimal(parseFloat(item.regional1));
                       rate = regionalDec.div(parseFloat(item.receipt_mark)).toDecimalPlaces(1).toNumber();
                    } else {
                       const districtDec = new Decimal(parseFloat(item.district1));
                       rate = districtDec.div(parseFloat(item.receipt_mark)).toDecimalPlaces(1).toNumber();
                    }
                    return (isNaN(rate) || !isFinite(rate) ? 0 : rate);
                }
            });
            Object.defineProperty(item, 'rate2', {
                get: function () {
                    let rate = 0;
                    if (that.region.endsWith('0101')) {
                        const regionalDec = new Decimal(parseFloat(item.regional2));
                        rate = regionalDec.div(parseFloat(item.regional1)).toDecimalPlaces(1).toNumber();
                    } else {
                        const districtDec = new Decimal(parseFloat(item.district2));
                        rate = districtDec.div(parseFloat(item.district1)).toDecimalPlaces(1).toNumber();
                    }
                    return (isNaN(rate) || !isFinite(rate) ? 0 : rate);
                }
            });
            Object.defineProperty(item, 'rate3', {
                get: function () {
                    let rate = 0;
                    if (that.region.endsWith('0101')) {
                        const regionalDec = new Decimal(parseFloat(item.regional3));
                        rate = regionalDec.div(parseFloat(item.regional2)).toDecimalPlaces(1).toNumber();
                    } else {
                        const districtDec = new Decimal(parseFloat(item.district3));
                        rate = districtDec.div(parseFloat(item.district2)).toDecimalPlaces(1).toNumber();
                    }
                    return (isNaN(rate) || !isFinite(rate) ? 0 : rate);
                }
            });

            if (item.visual) {
                this.$set(item, 'index', this.budgetForm.length);
                this.$set(this.budgetForm, this.budgetForm.length, item);
            }

            if (item.hasChild) {
                for (const ch of item.child) {
                    that.createTable(ch.value, item.id);
                }
                delete item.child;
            }
        }, // древовидную выборку преобразовывает в таблицу (для отображения)

        compareDatas() {
            const saveDatas = [];
            const user = store.state.user.login;
            this.ifDistrStandart = false;
            for (const row of this.spfMap.values()) {
                const val = this.valMap.get(this.getRowKey(row, ['kat', 'cls', 'pcl', 'spf']))
                                        .replace('"visible":true', '"visible":false');
                const newRow = {...row, visible: false};
                if (JSON.stringify(newRow) !== val) {
                    if (JSON.parse(val).regional1 - row.regional1 !== 0
                        || JSON.parse(val).regional2 - row.regional2 !== 0
                        || JSON.parse(val).regional3 - row.regional3 !== 0) {
                            this.changedRows.push(row);
                    }
                    if (row.standard && Object.keys(row.standard).length > 0
                        && ((row.standard.regional1 && row.standard.regional1 > 0)
                            || (row.standard.regional1 && row.standard.regional1 > 0)
                            || (row.standard.regional1 && row.standard.regional1 > 0)))
                            this.ifDistrStandart = true

                    saveDatas.push(this.newData(row.kat, parseInt(row.cls), row.pcl, parseInt(row.spf),
                        this.repYear, this.region, this.variant, user, this.repYear + 1,
                        parseFloat(row.receipt_mark), row.note !== null ? String(row.note) : null));

                    saveDatas.push(this.newData(row.kat, parseInt(row.cls), row.pcl, parseInt(row.spf),
                        parseInt(this.repYear) + 1, this.region, this.variant, user, this.repYear + 1,
                        parseFloat(row.receipt_mark), row.note,
                        parseFloat(row.contingent1), parseFloat(row.regional1), parseFloat(row.district1)));

                    saveDatas.push(this.newData(row.kat, parseInt(row.cls), row.pcl, parseInt(row.spf),
                        parseInt(this.repYear) + 2, this.region, this.variant, user, this.repYear + 1,
                        parseFloat(row.receipt_mark), row.note,
                        parseFloat(row.contingent2), parseFloat(row.regional2), parseFloat(row.district2)));

                    saveDatas.push(this.newData(row.kat, parseInt(row.cls), row.pcl, parseInt(row.spf),
                        parseInt(this.repYear) + 3, this.region, this.variant, user, this.repYear + 1,
                        parseFloat(row.receipt_mark), row.note,
                        parseFloat(row.contingent3), parseFloat(row.regional3), parseFloat(row.district3)));
                }
            }
            return saveDatas;
        }, // сравнивает введенные данные с ранее сохраненные и формирует массив новых записей для сохранения в БД

        focusout() {
            if (!this.editing) {
                return;
            }
            const res = this.compareDatas();
            if (res.length === 0) {
                return;
            }
            this.$bvModal.msgBoxConfirm(
                'Данные не сохранены. Вы действительно хотите покинуть страницу?',
                {
                    title: 'Подтверждение',
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    okTitle: 'Да',
                    cancelTitle: 'Нет',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if (value) {
                        // this.saveVariant(res);
                    } else {
                        this.editing = false;
                    }
                })
                .catch(error => {
                    this.makeToast('danger', 'Ошибка сохранения', error.toString());
                });
        }, // срабатывает запрос на сохранения данных, при потере фокуса области ввода

        getNumber(value, digit = 1) {
            if (typeof (value) === 'string' || typeof (value) === 'number') {
                const decValue = new Decimal(parseFloat(value));
                return decValue.toDecimalPlaces(digit).toNumber();
            }
            if (typeof (value) === 'boolean') {
                return (value === true ? 1 : 0);
            }
            if (typeof (value) === 'undefined') {
                return 0;
            }
            if (value === null) {
                return 0;
            }
            return 0;
        },

        getRowKey(row, keys) {
            let key = '';
            for (const k of keys) {
                key = key + this.padLeadingZeros(row[k], 3) + '.';
            }
            return key;
        }, // преобразует значения выбранных полей в код

        inputFixed(item, field, value, digit) {
            if (value && typeof value === 'string') value = value.replace(',', '.');
            let parsedValue = parseFloat(value);
            if (!parsedValue || value === '') {
                parsedValue = 0;
            }
            const decValue = new Decimal(parseFloat(parsedValue));
            this.$set(item, field, decValue.toDecimalPlaces(digit).toNumber());
            const c = field.toString().slice(-1);
            const decField = new Decimal(item[field])
            if (item.standard['regional' + c] > 0) {
                this.$set(item, 'regional' + c, decField.mul(item.standard['regional' + c]).toDecimalPlaces(0).toNumber());
                this.$set(item, 'regional' + c, parseFloat(item[field] * item.standard['regional' + c]).toFixed(0));
                this.$set(item, 'district' + c, parseFloat(item[field] * (1 - item.standard['regional' + c])).toFixed(0));
            }
            else {
                if (this.region.endsWith('0101')) {
                    this.$set(item, 'regional' + c, decField.toDecimalPlaces(1).toNumber());
                    this.$set(item, 'district' + c, 0);
                }
                else {
                    this.$set(item, 'regional' + c, 0);
                    this.$set(item, 'district' + c, decField.toDecimalPlaces(1).toNumber());
                }
            }

            this.editing = true;
        }, // форматирует введенное значение до digit цифр после запятой

        isStr(value) {
            return (value === null ? '' : value);
        },

        keyPress: function (event, pattern) {
            const regex = new RegExp(pattern);
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }, // вводит по заданному паттерну

        keyup13: function (event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
            // select/focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
        }, // enter работает как tab

        async loadCrossFields() {
            const that = this;

            try {
                const response = await fetch('/api-py/cross-fields/' + that.region);
                const items = await response.json();

                for (const row of items) {
                    that.$set(row, 'cls', row.clss);
                    const spf = that.spfMap.get(that.getRowKey(row, ['kat', 'cls', 'pcl', 'spf']));
                    if (spf !== undefined) {
                        spf.crossfields.push(row.field + (row.year - parseInt(that.repYear)));
                    }
                }
            } catch (error) {
                that.makeToast('danger', 'Ошибка запроса loadCrossFields', error.toString());
            }
        },

        async loadDistrStandard() {
            try {
                const response = await fetch('/api-py/distr-standard/' + this.region + '/' + (this.repYear + 1))
                const items = await response.json();
                this.standards = this.filterActualItems(items);
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadCrossFields', error.toString());
            }
        },

        async loadRegions() {
            try {
                const response = await fetch('/api-py/get-obl-regions/' + this.obl);
                this.listRegs = await response.json();
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadCrossFields', error.toString());
            }
        },

        async loadDictTree() {
            if (!this.repYear || !this.region || !this.variant) {
                return;
            }
            this.bar = 20;
            const varDates = {
                beg_date: this.variantObj.date_start,
                end_date: this.variantObj.end_date
            }

            try {
                // const response = await fetch('/api-py/limit-form/' + this.repYear + '/' + this.region + '/' + this.variant);
                const response = await fetch('/api-py/limit-form/' + this.repYear + '/' + this.region + '/' + this.variant, 
                {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(varDates)
                })
                const items = await response.json();

                this.dictTree = items.tree.slice(0, 3)
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadDictTree', error.toString());
            }
        },

        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }, // сообщение

        async getRegionsSums() {
            this.dsblUpdBtn = true;
            this.updAlert = false;
            try {
                const response = await fetch('/api-py/limit-form-all/' + this.repYear);
                if (response.status === 200) {
                    const items = await response.json();
                    if (items.length > 0) {
                        const filteredItems = [];
                        this.regVariant = this.listVariants.filter(item => item.variant_uuid === this.variant)[0];
                        this.regionVariants.length = 0;
                        const includedDataTypes = [3, 4].includes(this.regVariant.data_type) ? [2, 3, 4] : [this.regVariant.data_type];  
                        this.listRegs.forEach(reg => {
                            const variant = this.listVariants.filter(item => 
                                                item.region_code && !item.region_code.endsWith('0101') 
                                                && includedDataTypes.includes(item.data_type)
                                                && item.region_code === reg.code 
                                                && item.year === this.repYear + 1
                                                && (
                                                    (this.regVariant.attribute && item.attribute)
                                                    || (this.regVariant.date_start < item.end_date
                                                    && this.regVariant.end_date > item.date_start)
                                                )
                                            )[0]
                            if (variant) {
                                this.regionVariants.push(variant);
                            }
                        })
                        const getRegionName = (item, lang) => {
                            return this.listRegs.filter(reg => reg.code === item.__data__.region)[0]['name_'+lang];
                        }
                        this.regionVariants.forEach(variant => {
                            filteredItems.push(...items.filter(item => item.__data__.kat <= 3 && item.__data__.region === variant.region_code && item.__data__.variant === variant.variant_uuid)
                                                        .map(item => {
                                                            item.__data__.name_ru = getRegionName(item, 'ru')
                                                            item.__data__.name_kk = getRegionName(item, 'kk')
                                                            item.__data__.name_en = getRegionName(item, 'ru')
                                                            return item;
                                                        }))
                        })
                        const newFilteredItems = [];
                        filteredItems.forEach(fitem => {
                            if (this.standards.findIndex(titem => 
                            titem.region === fitem.__data__.region && 
                            titem.kat === fitem.__data__.kat && 
                            titem.pcl === fitem.__data__.pcl && 
                            titem.spf === fitem.__data__.spf && 
                            titem.clss === fitem.__data__.clss && 
                            titem.year === fitem.__data__.year && 
                            ((titem.beg_date <= this.regVariant.end_date) || !titem.beg_date) &&
                            ((titem.end_date >= this.regVariant.date_start) || !titem.end_date)) !== -1) { //
                                newFilteredItems.push(fitem);
                            }
                        })
                        this.budgetForm.forEach(bdg => {
                            if (bdg.type === 5) {
                                bdg.dstr1 = bdg.dstr2 = bdg.dstr3 = false;
                                bdg.summ1 = newFilteredItems.filter(item => item.__data__.clss === bdg.clss && item.__data__.kat === bdg.kat && item.__data__.pcl === bdg.pcl && item.__data__.spf === +bdg.spf && item.__data__.year === this.repYear + 1)
                                if (bdg.summ1.length > 0) {
                                    bdg.ccontingent1 = bdg.summ1.reduce((sum, elem) => { return sum + elem.__data__.regional }, 0)
                                    if (bdg.contingent1 === bdg.regional1 && bdg.district1 !== 0) bdg.dstr1 = true;
                                    if (bdg.contingent1 !== bdg.ccontingent1) bdg.inaccurate1 = true;
                                }
                                bdg.summ2 = newFilteredItems.filter(item => item.__data__.clss === bdg.clss && item.__data__.kat === bdg.kat && item.__data__.pcl === bdg.pcl && item.__data__.spf === +bdg.spf && item.__data__.year === this.repYear + 2)
                                if (bdg.summ2.length > 0) {
                                    bdg.ccontingent2 = bdg.summ2.reduce((sum, elem) => { return sum + elem.__data__.regional }, 0)
                                    if (bdg.contingent2 === bdg.regional2 && bdg.district2 !== 0) bdg.dstr2 = true;
                                    if (bdg.contingent2 !== bdg.ccontingent2) bdg.inaccurate2 = true;
                                }
                                bdg.summ3 = newFilteredItems.filter(item => item.__data__.clss === bdg.clss && item.__data__.kat === bdg.kat && item.__data__.pcl === bdg.pcl && item.__data__.spf === +bdg.spf && item.__data__.year === this.repYear + 3)
                                if (bdg.summ3.length > 0) {
                                    bdg.ccontingent3 = bdg.summ3.reduce((sum, elem) => { return sum + elem.__data__.regional }, 0)
                                    if (bdg.contingent3 === bdg.regional3 && bdg.district3 !== 0) bdg.dstr3 = true;
                                    if (bdg.contingent3 !== bdg.ccontingent3) bdg.inaccurate3 = true;
                                }
                                if (bdg.dstr1 || bdg.dstr2 || bdg.dstr3 || bdg.inaccurate1 || bdg.inaccurate2 || bdg.inaccurate3) {
                                    if (this.variantObj && !this.isItemDisabled) this.dsblUpdBtn = false;
                                    this.updAlert = true;
                                }
                            }
                        })
                    }
                    return [];
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса limit-form-all', error.toString());
            }
        },

        updateData() {
            if (this.region.endsWith('0101')) {
                this.budgetForm.forEach(bdg => {
                    if (bdg.type === 5) {
                        if (bdg.summ1.length > 0) {
                            if (bdg.inaccurate1 || bdg.dstr1) {
                                bdg.contingent1 = bdg.ccontingent1;
                                bdg.regional1 = bdg.ccontingent1;
                                bdg.district1 = 0;
                                bdg.inaccurate1 = false;
                                bdg.dstr1 = false;
                            }
                        }
                        if (bdg.summ2.length > 0) {
                            if (bdg.inaccurate2 || bdg.dstr2) {
                                bdg.contingent2 = bdg.ccontingent2;
                                bdg.regional2 = bdg.ccontingent2;
                                bdg.district2 = 0;
                                bdg.inaccurate2 = false;
                                bdg.dstr2 = false;
                            }
                        }
                        if (bdg.summ3.length > 0) {
                            if (bdg.inaccurate3 || bdg.dstr3) {
                                bdg.contingent3 = bdg.ccontingent3;
                                bdg.regional3 = bdg.ccontingent3;
                                bdg.district3 = 0;
                                bdg.inaccurate3 = false;
                                bdg.dstr3 = false;
                            }
                        }
                    }
                })
            }
            else {
                this.budgetForm.forEach(bdg => {
                    if (bdg.type === 5) {
                        if (bdg.reg1) {
                            bdg.regional1 = bdg.cregional1;
                            bdg.district1 = bdg.cdistrict1;
                            bdg.reg1 = false;
                        }
                        if (bdg.reg2) {
                            bdg.regional2 = bdg.cregional2;
                            bdg.district2 = bdg.cdistrict2;
                            bdg.reg2 = false;
                        }
                        if (bdg.reg3) {
                            bdg.regional3 = bdg.cregional3;
                            bdg.district3 = bdg.cdistrict3;
                            bdg.reg3 = false;
                        }
                    }
                })
            }
        },
        
        checkRegionalValues() {
            this.dsblUpdBtn = true;
            this.updAlert = false;
            this.budgetForm.forEach(bdg => {
                if (bdg.type === 5) {
                    bdg.reg1 = bdg.reg2 = bdg.reg3 = false;
                    for (let c = 1; c <= 3; c++) {
                        const regionalStandard = bdg.standard && bdg.standard['regional' + c] ? parseFloat(bdg.standard['regional' + c]) : 0;
                        const decField = new Decimal(bdg[`contingent${c}`]);
                        if (regionalStandard) {
                           bdg[`cregional${c}`] = decField.mul(regionalStandard).toDecimalPlaces(0).toNumber();
                           bdg[`cdistrict${c}`] = decField.mul(1 - regionalStandard).toDecimalPlaces(0).toNumber();
                        }
                        else {
                            bdg[`cregional${c}`] = 0;
                            bdg[`cdistrict${c}`] = decField.toDecimalPlaces(1).toNumber();
                        }
                        if (bdg[`regional${c}`] !== bdg[`cregional${c}`]) {
                            bdg[`reg${c}`] = true;
                        }
                    }
                    if (bdg.reg1 || bdg.reg2 || bdg.reg3) {
                        if (this.variantObj && !this.isItemDisabled) this.dsblUpdBtn = false;
                        this.updAlert = true;
                    }
                }
            })
        },

        newData(kat, cls, pcl, spf, year, region, variant, user_name, cur_year, cash = null, note = null, contingent = null, regional = null, district = null) {
            const update_date = new Date();
            const data = {
                kat: kat,
                cls: cls,
                pcl: pcl,
                spf: spf,
                year: year,
                region: region,
                variant: variant,
                user_name: user_name,
                cur_year: cur_year,
                update_date: update_date
            };
            if (year > this.repYear) {
                this.$set(data, 'contingent', contingent);
                this.$set(data, 'regional', regional);
                this.$set(data, 'district', district);
            } else {
                this.$set(data, 'cash', cash);
                this.$set(data, 'note', note);
            }
            return data;
        }, // формирует элемент для сохранения в БД

        openAll(forceOpen) {
            if (forceOpen) {
                if (forceOpen === 'open') {
                    this.open = true;
                } else {
                    this.open = false;
                }
            } else {
                this.open = !this.open;
            }
            for (const row of this.budgetForm) {
                row.visible = this.open;
                if ([1, 2, 3, 4].includes(row.type)) {
                    row.open = this.open;
                }
                if ([0, 1].includes(row.type)) {
                    row.visible = true;
                }
            }
        }, // открывает.закрывает все ветки

        openChilds(parent, bool) {
            parent.open = (bool === undefined ? !parent.open : bool);

            for (const row of this.budgetForm) {
                if (parent.id === row.parent_id) {
                    if ([1, 2, 3].includes(row.type)) {
                        this.openChilds(row, parent.open);
                    }
                    row.visible = parent.open;
                }
            }
        }, // открывает/закрывает ветку до конечного элемента

        padLeadingZeros(num, size) {
            let s = String(num);
            while (s.length < size) { s = '0' + s; }
            return s;
        }, // добавляет 0-ли перед num до size-значного размера

        async prepareForm() {
            await this.budgetForm.splice(0);
            for (const item of this.dictTree) {
                await this.createTable(item.value, 0);
            }
            this.getMaxPlanDate();
            this.openAll('close');
            this.dsblSendBtn = true;
            if (this.region.endsWith('0101')) {
                await this.loadStandards();
                await this.loadRegions();
                await this.getRegionsSums();
            }
            else {
                await this.loadDistrStandard();
                this.checkRegionalValues();
                //await this.getSums();
            }
            if (this.variantObj && !this.isItemDisabled) this.dsblSendBtn = false;
            this.bar = 80;
        }, // подготовка отображения данных

        async loadStandards() {
            try {
                const response = await fetch('/api-py/distr-standard-all/' + (this.repYear + 1));
                if (response.status === 200) {
                    const items = await response.json();
                    this.standards = this.filterActualItems(items);
                } else {
                    this.makeToast('danger', 'Предупреждение', 'Ошибка загрузки нормативов');
                }

            } catch (error) {
                that.makeToast('danger', 'Ошибка запроса loadStandards', error.toString());
            }
        },

        filterActualItems(items) {
            if (!this.variantObj || !items || items.length === 0) return items;
            const actualItems = [];
            items.forEach(itm => {
                const isItemStartedBeforeVarEnds = !itm.beg_date || itm.beg_date <= this.variantObj.end_date;
                const isItemEndedAfterVarStarts = !itm.end_date || itm.end_date >= this.variantObj.date_start;

                if (isItemStartedBeforeVarEnds && isItemEndedAfterVarStarts) actualItems.push(itm);
            })
            return actualItems;
        },

        getMaxPlanDate() {
            if (this.budgetForm.length > 0) {
                const maxDate = this.budgetForm.filter(item => item.type === 5)
                    .sort((a, b) => {
                        const aVal = a.revised_plan_date ? Date.parse(a.revised_plan_date) : 0;
                        const bVal = b.revised_plan_date ? Date.parse(b.revised_plan_date) : 0;
                        return bVal - aVal
                    })[0].revised_plan_date
                if (maxDate) {
                    const newMaxDate = new Date(maxDate)
                    newMaxDate.setDate(newMaxDate.getDate() + 1);
                    this.revisedPlanMaxDat = this.addZero(newMaxDate.getDate()) + '.' + this.addZero(newMaxDate.getMonth() + 1) + '.' + newMaxDate.getFullYear()
                }
            }
        },

        addZero(numb) {
            if (numb < 10) {
                return '0' + numb
            }
            return numb;
        },

        rememberDatas() {
            this.valMap.clear();
            for (const spf of this.spfMap.values()) {
                this.valMap.set(this.getRowKey(spf, ['kat', 'cls', 'pcl', 'spf']), JSON.stringify(spf));
                this.bar += 1;
            }

        }, // расставляет сохранненные данные по полям

        reSum(parent, field) {
            let sum = new Decimal(0);
            this.budgetForm.forEach(row => {
                if ((row.parent_id === parent.id)
                    && (row.type !== parent.type)) {
                    sum = sum.plus(this.toNum(row[field], 2));
                }
            });
            return sum.toDecimalPlaces(2).toNumber();
        }, // пересчет суммы

        rowClass(item, type) {
            if (!item || type !== 'row') { return; }
            if (!item.visible) { return 'is-hidden'; }
            return '';
        }, // задает класс 'is-hidden' заданной строке

        saveDatas() {
            const res = this.compareDatas();
            if (res.length > 0) {
                if (!this.region.endsWith('0101') && this.ifDistrStandart) {
                    const trigger = this.checkIfOblVariant();
                    if (!trigger) {
                        return;
                    }
                } 
                this.$bvModal.msgBoxConfirm(
                    'Вы действительно хотите сохранить данные?',
                    {
                        title: 'Подтверждение',
                        size: 'lg',
                        buttonSize: 'sm',
                        okVariant: 'success',
                        okTitle: 'ДА',
                        cancelTitle: 'Отмена',
                        footerClass: 'p-2',
                        hideHeaderClose: false,
                        centered: true
                    })
                    .then(value => {
                        if (value) {
                            //this.prepareSumToObl(res)
                            this.saveVariant(res);
                            this.editing = false;
                        } else {
                            this.editing = false;
                        }
                    })
                    .catch(error => {
                        this.makeToast('danger', 'Ошибка сохранения', error.toString());
                    });
            }
        }, // вызывает сохранение записей

        async saveVariant(item, updObl = true) {
            this.dsblSaveBtn = true;
            this.updAlert = false;
            try {
                const response = await fetch('/api-py/save-limit-budget', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(item)
                });
                const result = await response.json();

                if ((response.status === 200) && (result.result === 'success')) {
                    this.rememberDatas();
                    if (updObl) {
                        this.makeToast('success', 'Сообщение', 'Данные сохранены');
                    }

                }
            } catch {
                this.makeToast('danger', 'Предупреждение', 'Ошибка сохранения данных');
            }
            this.dsblSaveBtn = false;
        }, // сохранение данных в БД

        // проверяем есть ли версия для
        async checkIfOblVariant() {
            try {
                const response = await fetch('/api-py/get-all-regions-by-obl/' + this.obl);
                this.listAllReg = await response.json()
                if (this.listAllReg.length > 0) {
                    //  редактируемый ваиант
                    this.regVariant = this.listVariants.filter(item => item.variant_uuid === this.variant)[0];
                    this.regionVariants.length = 0
                    this.listAllReg.forEach(reg => {
                        const variant = this.listVariants.filter(item => item.attribute === true && item.data_type === this.regVariant.data_type && item.region_code === reg.code)[0]
                        if (variant) {
                            this.regionVariants.push(variant)
                        }
                    })
                    // область без регионов
                    this.currObl = this.regionVariants.filter(item => item.region_code.endsWith('0101'))[0];
                    let res = true;
                    if (!this.currObl) {
                        let massege = "Сохранение невозможно, нет актуальной версии бюджета области"
                        if (this.$i18n.locale === 'kk') {
                            massege =  "Сақтау мүмкін емес, облыс бюджетінің өзекті нұсқасы жоқ"
                        }
                        this.makeToast('danger', 'Ошибка сохранения', massege);
                        res = false;
                    }
                    return res;
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка поиска версий для сохранения', error.toString());
            }
            return false;
        },

        prepareOblData() {
            const saveDatas = [];
            const user = store.state.user.login;
        },

        toNum(value, fix = 1) {
            if (typeof (value) === 'string' || typeof (value) === 'number') {
                const decValue = new Decimal(parseFloat(value));
                return decValue.toDecimalPlaces(fix).toNumber();
            }
            if (typeof (value) === 'boolean') {
                return (value === true ? 1 : 0);
            }
            if ((value === 'undefined') || isNaN(value) || isFinite(value) || (value === null)) {
                return 0;
            }
        },

        total(field, digits = 2) {
            let sum = new Decimal(0);
            for (const row of this.budgetForm) {
                if (row.type === 1) {
                    sum = sum.plus(parseFloat(row[field]));
                }
            }
            return sum.toDecimalPlaces(digits).toNumber();
        }, // итого по заданному полю

        async updateDatas() {
            this.variant = this.variantObj.variant_uuid
            this.lastMonth = 0;
            this.open = true;
            if (this.variant) {
                this.isApproved = this.listOfVariants.filter(v => v.variant_uuid === this.variant)[0].status;
            }
            // await this.budgetForm.splice(0);
            await this.spfMap.clear();
            this.bar = 10;
            await this.loadDictTree();
            this.bar = 70;
            await this.prepareForm();
            this.bar = 100;
            await this.rememberDatas();
        }, // расставляет сохранненные данные по полям

        // ==============Отправка свода======================

        sendDatasToBugetForm() {
            // подготовка данных для отправки
            const res = this.prepareDataToBugetForm();

            if (res.length > 0) {
                this.$bvModal.msgBoxConfirm(
                    <span>Вы действительно хотите отправить свод? <br/><br/> <span class="text-danger">Внимание, перед отправкой сохраните все изменения!</span></span>,
                    {
                        title: 'Подтверждение',
                        size: 'lg',
                        buttonSize: 'sm',
                        okVariant: 'success',
                        okTitle: 'ДА',
                        cancelTitle: 'Отмена',
                        footerClass: 'p-2',
                        hideHeaderClose: false,
                        centered: true
                    })
                    .then(value => {
                        if (value) {
                            this.saveVariantIncomeForm(res);
                        }
                    })
                    .catch(error => {
                        this.makeToast('danger', 'Ошибка отправки свода', error.toString());
                    })
            }
        }, // инициализация подготовки и отправки свода

        prepareDataToBugetForm() {
            // проверка наличия данных в таблице
            if (this.budgetForm.length === 0) {
                this.makeToast('danger', 'Данные для отправки свода не найдены', error.toString());
                return;
            }

            // проверка является ли регион областью
            const isObl = this.region.substr(-4) === '0101' ? 1 : 0;

            const user = store.state.user.login;
            const saveDatas = [];

            for (const row of this.spfMap.values()) {
                // в зависимости от того является ли регион областью получаем соответствующий прогноз
                const plan1 = isObl ? parseFloat(row.regional1) : parseFloat(row.district1)
                const plan2 = isObl ? parseFloat(row.regional2) : parseFloat(row.district2)
                const plan3 = isObl ? parseFloat(row.regional3) : parseFloat(row.district3)

                if (plan1 !== null && row.spf !== "None") {
                    saveDatas.push(this.newDataForForm(
                        row.kat, row.cls, row.pcl, row.spf,
                        plan1, row.note, parseInt(this.repYear) + 1,
                        this.region, this.variant, user, this.repYear + 1));
                }

                if (plan2 !== null && row.spf !== "None") {
                    saveDatas.push(this.newDataForForm(
                        row.kat, row.cls, row.pcl, row.spf,
                        plan2, row.note, parseInt(this.repYear) + 2,
                        this.region, this.variant, user, this.repYear + 1));
                }

                if (plan3 !== null && row.spf !== "None") {
                    saveDatas.push(this.newDataForForm(
                        row.kat, row.cls, row.pcl, row.spf,
                        plan3, row.note, parseInt(this.repYear) + 3,
                        this.region, this.variant, user, this.repYear + 1));
                }
            }
            return saveDatas;
        }, // подготовка данных для отправки свода

        newDataForForm(kat, cls, pcl, spf, plan, note, year, region, variant, user_name, cur_year) {
            return {
                kat: kat,
                cls: cls,
                pcl: pcl,
                spf: spf,
                amount: plan,
                note: note,
                year: year,
                region: region,
                variant: variant,
                user_name: user_name,
                cur_year: cur_year,
                data_type: this.variantObj.data_type
            }
        }, // формирует элемент для сохранения в БД

        async saveVariantIncomeForm(item) {
            try {
                const response = await fetch('/api-py/save-income-budget', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(item)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    await this.rememberDatas();
                    this.makeToast('success', 'Сообщение', 'Свод отправлен');
                }
            } catch {
                this.makeToast('danger', 'Предупреждение', 'Ошибка отправки свода');
            }
        }, // сохранение свода в БД

        getPeriodLst() {
            const startDate = 2021;
            const endDate = this.year + 1;
            for (let i = endDate; i >= startDate; i--) {
                this.periodLst.push({ name: `${i} - ${i + 2}`, year: i - 1 });
                if (this.year + 1 === i) {
                    this.curPeriod = { name: `${i} - ${i + 2}`, year: i - 1 };
                }
            }
        }, // готовит список периодов для фильтра

        //=========== фильтрация данных в столбцах ===============

        filterFunction(row, val) {
            const { kat, cls, pcl, spf, name_ru } = val;
            const isOpen = [
                !kat || Number(kat) === row.kat,
                !cls || row.cls.indexOf(cls) !== -1,
                !pcl || Number(pcl) === row.pcl,
                !spf || row.spf.indexOf(spf) !== -1,
                !name_ru || row.name_ru.toLowerCase().indexOf(name_ru.toLowerCase()) !== -1
            ].every(Boolean);
            if ((kat || cls || pcl || spf || name_ru) && !this.filtered) {
                this.filtered = true;
                this.openAll('open');
            }
            if (!(kat || cls || pcl || spf || name_ru) && this.filtered) {
                this.filtered = false;
                this.openAll('close');
            }
            return isOpen;
        },
        
        async incomeGenerationPSER() {
            //--------------------------------------------------------------------------------------------------------//
            const paramsNew = JSON.stringify({ curYear: parseInt(this.repYear), obl: this.obl, region: this.region, variant: this.variant });
            const urlNew = '/api-py/budget_income_pser/'+encodeURI(paramsNew);
            Ax(
                {
                    url: urlNew, //  Тело файла
                    method: 'POST',
                    data: null,
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'отчеты Формирование доходов ПСЭР.xls');// or any other extension
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    this.error = error;
                }
            );
        },

        openFilterByRef(refName) {
            const drop = this.$refs.drop;
            drop.show(true);
            const refItem = this.$refs[refName];
            setTimeout(() => refItem.$el.focus(), 100);
        }
    }
};
</script>

<style>
    .filtered {
        color: #95B8CD;
        display: inline-block;
        margin-right: 5px;
    }
    .warning-content {
        display:inline-grid;
        width:50%; 
        vertical-align: middle;
        margin-right: 10px;
        margin-left: 10px;
    }
</style>